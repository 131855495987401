import { mixinMutations } from '@/store/mixins';
import initState from './entities/eventsDefaultState';
const state = initState();
const mutations = { ...mixinMutations() };
const actions = {
    async getEventsList({ commit, rootGetters }, { params }) {
        const response = await this.getDataStore(`${rootGetters.API_URL}/events`, params);
        commit('setProperty', {
            name: 'events',
            value: response.data
        });
        return response;
    },
    async getEventsListForEdit({ rootGetters }, params) {
        return await this.getDataStore(`${rootGetters.API_URL}/events`, params);
    },
    async getCategoriesList({ rootGetters }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/event_categories`);
        return data;
    },
    async getTeachersList({ rootGetters }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/event_teachers?all=1`);
        return data;
    },
    async getSeo({ rootGetters }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/events_main_page_settings`);
        return data;
    },
    async updateSeo({ rootGetters }, params) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/events_main_page_settings`, params, 'PUT');
        return data;
    },
    async deleteEvent({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/events/${id}`, {}, 'DELETE');
    },
    async createEvent({ rootGetters }, { params }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/events`, params);
        return data;
    },
    async updateEvent({ rootGetters }, { id, params }) {
        const categories = params?.categories?.map((item) => (item?.id ? item.id : item));
        return await this.postDataStore(`${rootGetters.API_URL}/events/${id}`, { ...params, categories }, 'PUT');
    },
    async deleteImage({ rootGetters }, { media_id, event_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/events/${event_id}/delete_media/${media_id}`, {}, 'POST');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
