import { getData } from '@/api';
const mutations = {
    createTotal(state, payload) {
        state.totals.push(payload);
    },
    updateTotal(state, payload) {
        // @ts-ignore
        const index = state.totals.findIndex((total) => total.id === +payload.id);
        if (index !== -1) {
            // @ts-ignore
            const { cells_by_sort } = state.totals[index];
            state.totals.splice(index, 1, { ...payload, cells_by_sort });
        }
    },
    deleteTotal(state, payload) {
        // @ts-ignore
        const index = state.totals.findIndex((total) => total.id === +payload.id);
        if (index !== -1) {
            state.totals.splice(index, 1);
        }
    }
};
const actions = {
    async getTotals({ rootGetters, commit }, { listId, params = {} } = {}) {
        commit('setProperty', { name: 'totals', value: [] });
        const res = await this.getDataStore(`${rootGetters.API}/united_table_lists/${listId}/united_table_list_totals/index_with_cells_values`, { ...params, sort: 'sort' });
        if (!res.errors) {
            commit('setProperty', { name: 'totals', value: res.data });
        }
        return res;
    },
    async createTotal({ rootGetters, commit, dispatch, state }, { listId, ...params } = {}) {
        const res = await this.postDataStore(`${rootGetters.API}/united_table_lists/${listId}/united_table_list_totals`, { ...params, with: 'cells;items' }, 'POST');
        if (!res.errors) {
            dispatch('getTotals', { params: { page: 1 }, listId: state.currentListId });
            commit('createTotal', res.data);
        }
        return res;
    },
    async updateTotal({ rootGetters, commit }, { listId, id, ...params } = {}) {
        const res = await this.postDataStore(`${rootGetters.API}/united_table_lists/${listId}/united_table_list_totals/${id}`, { ...params, with: 'cells;items' }, 'PUT');
        if (!res.errors) {
            commit('updateTotal', res.data);
        }
        return res;
    },
    async deleteTotal({ rootGetters, commit }, { listId, id } = {}) {
        const res = await this.postDataStore(`${rootGetters.API}/united_table_lists/${listId}/united_table_list_totals/${id}`, {}, 'DELETE');
        if (!res.errors) {
            commit('deleteTotal', { id });
        }
    },
    async attachToTotal({ rootGetters }, { listId, id, items_ids } = {}) {
        return await this.postDataStore(`${rootGetters.API}/united_table_lists/${listId}/united_table_list_totals/${id}/attach_items`, { items_ids }, 'POST');
    },
    async detachFromTotal({ rootGetters }, { listId, id, items_ids } = {}) {
        return await this.postDataStore(`${rootGetters.API}/united_table_lists/${listId}/united_table_list_totals/${id}/detach_items`, { items_ids }, 'POST');
    },
    async multiSortTotals({ rootGetters }, { listId, totals_ids }) {
        return await this.postDataStore(`${rootGetters.API}/united_table_lists/${listId}/united_table_list_totals/multi_sort`, { totals_ids }, 'POST');
    },
    async getRegList({ rootGetters }, { course_id, per_page, coursePackageIds }) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/reg_lists`, {
            per_page,
            coursePackageIds
        });
    },
    async actualizeRegList({ rootGetters }, { course_id, reglists_ids = [] }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/reg_lists/actualize`, { reglists_ids }, 'POST');
    },
    async downloadRegList({ rootGetters }, { course_id }) {
        return await getData(`${rootGetters.API_URL}/courses/${course_id}/reg_lists/download_export`, { desc: 1 });
    },
    async checkForRegList({ rootGetters }, { file_name, course_id }) {
        return await getData(`${rootGetters.API_URL}/courses/${course_id}/reg_lists/download_file`, { file_name });
    }
};
export default { mutations, actions };
